
import { Options, Vue } from "vue-class-component";
import Accordion from "@/components/Accordion.vue";
import Tooltip from "@/components/Tooltip.vue";
import Table from "@/components/Table.vue";
import Badge from "@/components/Badge.vue";
import CheckboxInput from "@/components/Form/CheckboxInput.vue";
import SelectItem from "@/components/Form/SelectItem.vue";
import { Constants } from "@/constants/constants";
import {
  CourseOccurrenceBase,
  PagedList,
  SortOrder,
  QueryStringParameters,
  NoteTakerOffer,
} from "@/interfaces/api";
import { INameAndId } from "@/interfaces/INameAndId";
import { IPagingData } from "@/interfaces/IPagingData";
import { IUserPagingData } from "@/interfaces/IUserPagingData";
import CourseService from "@/services/course-service";
import LocalStorageService from "@/services/localstorage-service";
import DateHelper from "@/helpers/date-helper";
import MappingHelper from "@/helpers/mapping-helper";
import StringHelper from "@/helpers/string-helper";
import CoordinatorService from "@/services/coordinator-service";
import { mapState } from "vuex";

@Options({
  components: {
    Table,
    Badge,
    Tooltip,
    Accordion,
    SelectItem,
    CheckboxInput,
  },
  data: () => ({
    /**
     * The current sort column
     */
    sortColumn: "endDate",
    /**
     * If sort order is ascending
     */
    ascending: false,
    /**
     * All course ocurrences
     */
    courseOccurrences: [],
    /**
     * Holds the paging data sent back with the list of course occurrences from the backend
     */
    pagingData: {},
    /**
     * The url to detailed view
     */
    navigateToDetailsUrl: "/administrator/courseoccurrencedetails/",
    /**
     * If the data is still loading - passed to the table component to show spinner
     */
    isLoading: true,
    /**
     * The length to truncate the course title to
     */
    courseTitleTruncationLength: Constants.CourseTitleTruncationLength,
    /*
     * Holds a list of coordinator names and ids and is populated by a call to the coordinator service when the page loads
     */
    coordinators: new Array<INameAndId>(),
    /**
     * The selected coordinator to filter on
     */
    selectedCoordinator: { id: 0, name: "" },
  }),
  computed: {
    /**
     * Hides course title column in one language depending on chosen locale
     */
    hiddenColumns() {
      return this.$i18n.locale === "sv" ? ["id", "titleEn"] : ["id", "titleSv"];
    },
    /**
     * Columns that should not be sortable
     */
    unsortableColumns() {
      return ["latestNoteTakerOffer"];
    },
    /**
     * The page heading to be displayed above the table
     */
    pageHeading() {
      return this.$t("shared.routes.course-occurrence-overview");
    },
    /**
     * All rows in the table
     */
    rows() {
      return MappingHelper.mapToPartialCourseOccurrenceOverviewArray(
        this.courseOccurrences
      );
    },
    ...mapState(["currentUser"]),
  },
  methods: {
    /**
     * Fetches and filters course instance offers to output the first offer date
     */
    getLatestCourseInstanceOfferDate(data: NoteTakerOffer[]): string {
      const activeOffers = data.filter((offer) => {
        return (
          (offer.canceled === null || offer.canceled === undefined) &&
          (offer.confirmed === null || offer.confirmed === undefined)
        );
      });
      const sortedActiveOffers = activeOffers.sort((offerA, offerB) => {
        return DateHelper.dateCompareFunction(offerA.sent, offerB.sent);
      });
      if (!sortedActiveOffers[0]) {
        return "";
      }
      return DateHelper.formatDate(sortedActiveOffers[0]?.sent);
    },
    /**
     * Fetches course occurrences that meet the required paging data
     */
    getCourseOccurrences(): void {
      const parameters = this.getQueryStringParameters();
      this.isLoading = true;
      CourseService.getCourseOccurrences(parameters)
        .then((data: PagedList<CourseOccurrenceBase>) => {
          this.populatePagingData(data);
          this.courseOccurrences = data.items;
          this.rows = MappingHelper.mapToPartialCourseOccurrenceOverviewArray(
            data.items
          );
          this.isLoading = false;
          this.saveFilteredCourseOccurrenceIds(data.sortedIds);
        })
        .catch((e: Error) => {
          console.error(e);
        });
    },
    /**
     * Populates and returns parameters for the getCourseOccurrences method in the course service
     */
    getQueryStringParameters(): QueryStringParameters {
      return {
        sortBy: this.sortColumn,
        sortOrder: this.ascending ? SortOrder.Asc : SortOrder.Desc,
        pageNumber: this.pagingData.currentPage || 1,
        pageSize: this.pagingData.pageSize || 10,
        coordinatorId: this.selectedCoordinator.id,
      };
    },
    /**
     * Gets the user's paging data that has been saved to local storage
     */
    getUsersPagingData(): void {
      const userPagingData = LocalStorageService.getItem<IUserPagingData>(
        Constants.CourseOccurrenceTableUserPagingData
      );

      this.pagingData.pageSize = 10;

      if (userPagingData) {
        this.sortColumn = userPagingData.sortColumn;
        this.ascending = userPagingData.sortOrder === SortOrder.Asc;
        this.pagingData.pageSize = userPagingData.pageSize;
        if (userPagingData.coordinator) {
          this.selectedCoordinator = userPagingData.coordinator;
        }
      }
    },
    /**
     * Populates paging data received from the backend to be passed to the table component
     */
    populatePagingData(data: PagedList<CourseOccurrenceBase>): void {
      const pagingData: IPagingData = {
        currentPage: data.currentPage,
        totalPages: data.totalPages,
        pageSize: data.pageSize,
        totalCount: data.totalCount,
        hasPrevious: data.hasPrevious,
        hasNext: data.hasNext,
      };

      this.pagingData = pagingData;
    },
    /**
     * Generates user paging data to be saved to local storage
     */
    generateUserPagingData(): IUserPagingData {
      const userPagingData: IUserPagingData = {
        sortColumn: this.sortColumn,
        sortOrder: this.ascending ? SortOrder.Asc : SortOrder.Desc,
        pageSize: this.pagingData.pageSize,
        coordinator: this.selectedCoordinator,
      };

      return userPagingData;
    },
    /**
     * Saves the ids of all sorted course occurrences to localstorage
     *
     * @param {QueryStringParameters} parameters - The paging parameters to be added to the service call
     */
    saveFilteredCourseOccurrenceIds(sortedIds: number[]): void {
      LocalStorageService.setItem(
        Constants.SortedCourseOccurrenceIds,
        sortedIds
      );
    },
    /**
     * Returns a color based on status
     *
     * @param {string} status The status we want a color for
     */
    statusBgColor(status: string): string {
      return CourseService.courseOccurrenceStatusColor(status);
    },
    /**
     * Returns a color based on status
     *
     * @param {string} status The status we want a color for
     */
    statusTextColor(status: string): string {
      return status === "Yellow" ? "black" : "white";
    },
    /**
     * Returns a text based on status
     *
     * @param {string} status The status we want a tooltip text for
     */
    statusText(status: string): string {
      return this.$t(CourseService.courseOccurrenceStatusText(status));
    },
    /**
     * Returns a string truncated to the end of the last word in the substring with a trailing "..."
     *
     */
    truncate(input: string, stringLength: number): string {
      return StringHelper.truncate(input, stringLength, true);
    },
    /**
     * Triggered when the user changes the page number of the table
     */
    onPageNumberChange(pageNumber: number): void {
      this.pagingData.currentPage = pageNumber;
      this.getCourseOccurrences();
    },
    /**
     * Triggered when the user changes the sort column/order of the table
     */
    onSortChange(columnName: string, ascending: boolean): void {
      this.sortColumn = this.getColumnName(columnName);
      this.ascending = ascending;
      LocalStorageService.setItem(
        Constants.CourseOccurrenceTableUserPagingData,
        this.generateUserPagingData()
      );
      this.getCourseOccurrences();
    },
    /**
     * Triggered when the user changes the sort column/order of the table
     */
    getColumnName(columnName: string): string {
      if (columnName === "title") {
        return this.$i18n.locale === "sv" ? "titleSv" : "titleEn";
      }
      return columnName;
    },
    /**
     * Triggered when the user changes the page size of the table
     */
    onPageSizeChange(pageSize: number): void {
      this.pagingData.pageSize = pageSize;
      LocalStorageService.setItem(
        Constants.CourseOccurrenceTableUserPagingData,
        this.generateUserPagingData()
      );
      this.getCourseOccurrences();
    },
    /**
     * Triggered when the user changes the selected coordinator option
     */
    onCoordinatorChange(coordinator: INameAndId): void {
      this.selectedCoordinator = coordinator;
      this.pagingData.coordinator = coordinator;
      LocalStorageService.setItem(
        Constants.CourseOccurrenceTableUserPagingData,
        this.generateUserPagingData()
      );
      this.getCourseOccurrences();
    },
    /**
     * Fetches the coordinators for the function with the supplied id
     */
    getCoordinators() {
      CoordinatorService.getCachedCoordinatorsByFunctionId(
        Constants.NoteTakingFunctionId
      ).then((coordinators) => {
        this.coordinators = coordinators;
      });
    },
    /**
     * Checks if the specified value is unavailable and if so,
     * replaces it with the the unavailable translation for the current locale
     * Also checks if the value is a date and if so, formats it to YYYY-MM-DD
     */
    replaceUnvailableOrFormatDate(value: string): string {
      return CourseService.replaceUnvailableOrFormatDate(
        value,
        this.$t("course-occurrence.unavailable")
      );
    },
  },
  mounted() {
    this.getUsersPagingData();
    this.getCourseOccurrences();
    this.getCoordinators();
  },
})
export default class CourseOccurrenceList extends Vue {}
